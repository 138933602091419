<template>
  <el-dialog
    @close="closeDialog"
    :title="
      editData
        ? $t('promocodesPageDialogEditLabel')
        : $t('promocodesPageDialogAddLabel')
    "
    :visible.sync="internalFlag"
    top="5vh"
  >
    <el-form
      :rules="rules"
      ref="promocodeCreateDialogRef"
      :model="dialogFormData"
    >
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelName')"
        prop="code"
        label-width="160px"
      >
        <el-input
          size="mini"
          v-model="dialogFormData.code"
          autocomplete="off"
          :disabled="editData != null"
        ></el-input>
      </el-form-item>
      <div
        v-if="
          promocodeType != 'target' ||
          (editData?.is_target !== 1 && editData != null)
        "
      >
        <el-form-item
          class="promoCodeDate"
          label="Start Date"
          prop="start_date"
          label-width="160px"
        >
          <el-date-picker
            v-model="dialogFormData.start_date"
            type="datetime"
            placeholder="Pick a date"
            size="default"
            value-format="timestamp"
            :disabled="
              editData != null && startDateOfPromoCode() <= dateOfNow()
            "
          />
          <!-- value-format="yyyy-M-d" -->
          <!-- :disabled="editData != null && (dialogFormData.isActive == false || startDateOfPromoCode() <= dateOfNow())" -->
        </el-form-item>
        <el-form-item
          class="promoCodeDate"
          label="End Date"
          prop="end_date"
          label-width="160px"
        >
          <el-date-picker
            v-model="dialogFormData.end_date"
            type="datetime"
            placeholder="Pick a date"
            size="default"
            value-format="timestamp"
          />
          <!-- :disabled="(dialogFormData.isActive == false || dialogFormData.isActive == true) && editData != null" -->
        </el-form-item>
      </div>

      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelStatus')"
        prop="isActive"
        label-width="160px"
      >
        <el-switch
          v-model="dialogFormData.isActive"
          :disabled="dialogFormData.T1_removable === 1"
        ></el-switch>
        <div class="u-margin-left--2x promocodes-dialog__status-active-label">
          {{
            dialogFormData.isActive
              ? $t("promocodesPageTableActiveLabel")
              : $t("promocodesPageTableInactiveLabel")
          }}
        </div>
      </el-form-item>
      <el-form-item
        label="WHS Promo Code"
        prop="wholesaler_promocode"
        label-width="160px"
      >
        <el-switch
          v-model="dialogFormData.wholesaler_promocode"
          :disabled="editData"
        ></el-switch>
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelDiscAmount')"
        prop="discount_amount"
        label-width="160px"
      >
        <el-input
          type="number"
          size="mini"
          v-model="dialogFormData.discount_amount"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelMinItems')"
        prop="min_quantity"
        label-width="160px"
      >
        <el-input
          type="number"
          size="mini"
          v-model="dialogFormData.min_quantity"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelMinOrderValue')"
        prop="min_amount"
        label-width="160px"
      >
        <el-input
          type="number"
          size="mini"
          v-model="dialogFormData.min_amount"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <div
        v-if="discrepancyAmount"
        class="promocodes-dialog__discrepancy-message"
      >
        NB: The Discrepancy between the Min. Order Value and Discount Amount is
        {{ discrepancyAmount }}%
      </div>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelDescription')"
        prop="notes"
        label-width="160px"
      >
        <el-input
          type="textarea"
          autosize
          v-model="dialogFormData.notes"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelSelectAllWholesalers')"
        prop="select_all_wholesalers"
        label-width="200px"
      >
        <el-switch v-model="dialogFormData.selectAllFlag"></el-switch>
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelAssign')"
        prop="wholesalers"
        label-width="160px"
      >
        <dropdownSelectAll
          ref="wholesalersDropdown"
          v-model="dialogFormData.wholesalerIds"
          :source="allWholesalers"
          :placeholder="
            $t('promocodesPageDialogWholesalersDropdownPlaceholder')
          "
          :disabled="dialogFormData.selectAllFlag"
        >
          <template v-slot:optionBody>
            <el-option
              v-for="item in allWholesalers"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </template>
        </dropdownSelectAll>
      </el-form-item>
      <el-form-item
        :label="`Exclude Wholesalers`"
        prop="wholesalers"
        label-width="160px"
        v-if="dialogFormData.selectAllFlag"
      >
        <el-select
          v-model="dialogFormData.excluded_wholesaler_ids"
          :placeholder="
            $t('promocodesPageDialogWholesalersDropdownPlaceholder')
          "
          clearable
          filterable
          multiple
          style="width: 100%"
        >
          <el-option
            v-for="item in allWholesalers"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <!-- <dropdownSelectAll
          ref="wholesalersDropdown"
          v-model="dialogFormData.excluded_wholesaler_ids"
          :source="allWholesalers"
          :placeholder="
            $t('promocodesPageDialogWholesalersDropdownPlaceholder')
          "
        >
          <template v-slot:optionBody>
            <el-option
              v-for="item in allWholesalers"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </template>
        </dropdownSelectAll> -->

        <!-- <el-select style="width: 100%;"
                    filterable
                    v-model="dialogFormData.wholesalerIds"
                    multiple
                    :disabled="dialogFormData.selectAllFlag"
                    placeholder="Select Wholesalers"
                >
                    <el-option
                        v-for="item in allWholesalers"
                        :key="item.key"
                        :label="item.value"
                        :value="item.key"
                    >
                    </el-option>
                </el-select>
                 -->
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelSections')"
        prop="sections"
        label-width="160px"
      >
        <dropdownSelectAll
          ref="wholesalersDropdown"
          v-model="dialogFormData.sectionKeys"
          :source="allSections"
          :placeholder="$t('promocodesPageDialogSectionsDropdownPlaceholder')"
        >
          <template v-slot:optionBody>
            <el-option
              v-for="item in allSections"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </template>
        </dropdownSelectAll>
      </el-form-item>
      <el-form-item
        :label="`Payment Method`"
        prop="payment_method"
        label-width="160px"
      >
        <el-select
          v-model="dialogFormData.payment_method"
          :placeholder="`Select payment method`"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in paymentMethodOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('promocodesPageDialogFieldLabelCategories')"
        prop="categories"
        label-width="160px"
      >
        <dropdownSelectAll
          ref="wholesalersDropdown"
          v-model="dialogFormData.categoryIds"
          :source="allCategories"
          :placeholder="
            $t('promocodesPageDialogSelectCategoriesDropdownPlaceholder')
          "
        >
          <template v-slot:optionBody>
            <el-option
              v-for="item in allCategories"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </template>
        </dropdownSelectAll>
      </el-form-item>

      <el-form-item :label="`Promocode Type`" label-width="160px">
        <el-select
          v-model="promocodeType"
          :placeholder="`Type`"
          style="width: 100%"
          clearable
          @change="promocodeTypeChanged"
          :disabled="editData != null"
        >
          <el-option
            v-for="item in typeDropdownData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="promocodeType">
        <el-form-item
          :label="$t('promocodesPageDialogFieldLabelMaxUsage')"
          prop="max_uses"
          label-width="160px"
        >
          <el-input
            type="number"
            size="mini"
            v-model="dialogFormData.max_uses"
            autocomplete="off"
            :disabled="
              editData &&
              dialogFormData.is_target &&
              dialogFormData.is_target == 1
            "
          ></el-input>
        </el-form-item>
      </template>
      <template v-if="promocodeType == 't0'">
        <!-- <el-form-item
          :label="$t('promocodesPageDialogFieldLabelCheckboxT1')"
          prop="T1_removable"
          label-width="200px"
        >
          <el-checkbox
            v-model="dialogFormData.T1_removable"
            :disabled="dialogFormData.isActive === false"
            :true-label="1"
            :false-label="0"
            @change="autoAssignCheckboxValueChanged"
          ></el-checkbox>
        </el-form-item> -->
        <template v-if="dialogFormData.T1_removable === 1">
          <el-form-item
            :label="$t('promocodesPageDialogFieldLabelAllRegions')"
            prop="select_all_regions"
            label-width="200px"
          >
            <el-switch
              :disabled="dialogFormData.isActive === false"
              @change="selectAllRegionsValueChanged"
              v-model="dialogFormData.selectAllRegions"
            ></el-switch>
          </el-form-item>
          <el-form-item
            :label="$t('promocodesPageDialogFieldLabelRegions')"
            prop="T1_regions_removable"
            label-width="160px"
          >
            <el-select
              v-model="dialogFormData.T1_regions_removable"
              :placeholder="
                $t('promocodesPageDialogFieldSelectRegionPlaceholder')
              "
              clearable
              filterable
              multiple
              style="width: 100%"
              :disabled="
                dialogFormData.selectAllRegions ||
                dialogFormData.isActive === false
              "
            >
              <el-option
                v-for="item in allRegions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <dropdownSelectAll
            ref="regionsDropdown"
            v-model="dialogFormData.T1_regions_removable"
            :source="allWholesalers"
            
            :disabled="dialogFormData.selectAllRegions"
          >
            <template v-slot:optionBody>
              <el-option
                v-for="item in allWholesalers"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </template>
          </dropdownSelectAll> -->
          </el-form-item>
        </template>
      </template>
      <template v-if="promocodeType == 'target'">
        <!-- <el-form-item :label="`Is Target`" prop="is_target" label-width="160px">
          <el-switch
            :disabled="editData != null"
            v-model="dialogFormData.is_target"
          />
        </el-form-item> -->
        <div style="display: flex">
          <el-form-item
            :label="`Start Date`"
            prop="start_date"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.start_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`End Date`"
            prop="end_date"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.end_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </template>

      <!-- new -->

      <!-- new end -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">{{
        $t("promocodesPageDialogButtonLabelCancel")
      }}</el-button>
      <el-button type="success" v-if="editData" @click="editPromoCodeClicked">{{
        $t("promocodesPageDialogButtonLabelEdit")
      }}</el-button>
      <el-button type="success" v-else @click="addPromoCodeClicked">{{
        $t("promocodesPageDialogButtonLabelAdd")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dropdownSelectAll from "../../../components/dropdownSelectAll.vue";
import moment from "moment";
export default {
  name: "PromocodePageDialog",
  components: {
    dropdownSelectAll,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      required: false,
      default: null,
    },
  },
  data() {
    let discountAmountValidator = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error(this.$t("promocodesPageValidationRule2DiscAmount")));
      } else {
        callback();
      }
    };
    let minOrderValueValidator = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error(this.$t("promocodesPageValidationRule2MinOrder")));
      } else {
        callback();
      }
    };
    let validateStartDate = (rule, value, callback) => {
      let now = new Date();
      let nowUnixTimeStamp = moment(now).valueOf();
      let valueUnixTimeStamp = moment(value).valueOf();
      // let startValueUnixTimeStamp = moment(this.editData.promoCodeStartDate).startOf('day').valueOf();
      if (valueUnixTimeStamp < nowUnixTimeStamp && this.editData == null) {
        return callback(
          new Error("Please Select greater than the current date.")
        );
      } else {
        callback();
      }
    };
    let validateEndDate = (rule, value, callback) => {
      let now = new Date();
      let nowUnixTimeStamp = moment(now).valueOf();
      let valueUnixTimeStamp = moment(value).valueOf();
      if (valueUnixTimeStamp < nowUnixTimeStamp && this.newData == null) {
        return callback(
          new Error("Please Select greater than the current date.")
        );
      } else {
        callback();
      }
    };
    return {
      internalFlag: false,
      rules: {
        code: [
          {
            required: true,
            message: this.$t("promocodesPageValidationRuleName"),
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "Please Enter a Promocode start date",
            trigger: "blur",
          },
          {
            validator: validateStartDate,
            trigger: "blur",
          },
        ],
        end_date: [
          {
            required: true,
            message: "Please Enter a Promocode End date",
            trigger: "blur",
          },
          {
            validator: validateEndDate,
            trigger: "blur",
          },
          // {
          //   validator:validateDates,
          //   trigger: "blur",
          // },
        ],
        discount_amount: [
          {
            required: true,
            message: this.$t("promocodesPageValidationRuleDiscountAmounr"),
            trigger: "blur",
          },
          {
            validator: discountAmountValidator,
            trigger: "blur",
          },
        ],
        // max_uses: [
        //   {
        //     required: true,
        //     message: this.$t("promocodesPageValidationRuleMaxUsage"),
        //     trigger: "blur",
        //   },
        // ],
        min_quantity: [
          {
            required: true,
            message: this.$t("promocodesPageValidationRuleMinItems"),
            trigger: "blur",
          },
        ],
        min_amount: [
          {
            required: true,
            message: this.$t("promocodesPageValidationRuleMinAmount"),
            trigger: "blur",
          },
          {
            validator: minOrderValueValidator,
            trigger: "blur",
          },
        ],
        payment_method: [
          {
            required: true,
            message: "Please select payment method",
            trigger: "blur",
          },
        ],
      },
      // promocodeCreateData: {
      //     isActive:true,
      //     max_uses:1,
      //     min_quantity:1,
      //     min_amount:50
      // },
      defaultCreateData: {
        isActive: true,
        max_uses: 1,
        min_quantity: 1,
        min_amount: 50,
        wholesalerIds: [],
        selectAllFlag: true,
        categoryIds: [],
        sectionKeys: [],
        selectAllRegions: true,
        T1_regions_removable: [],
        T1_removable: 0,
        excluded_wholesaler_ids: [],
        paymentMethod: null,
        is_target: false,
        start_date: null,
        end_date: null,
        wholesaler_promocode: false,
      },
      dialogFormData: null,
      paymentMethodOptions: [
        {
          label: "Cash",
          value: "cash",
        },
        {
          label: "Credit",
          value: "credit",
        },
        {
          label: "Both",
          value: "both",
        },
      ],
      typeDropdownData: [
        {
          label: "Target Promo-Code",
          value: "target",
        },
        {
          label: "T0 Promo-Code",
          value: "t0",
        },
      ],
      promocodeType: null,
    };
  },
  methods: {
    dateOfNow() {
      let nowDate = new Date();
      console.log("Date of now", moment(nowDate).valueOf());
      return moment(nowDate).valueOf();
    },
    startDateOfPromoCode() {
      console.log("startDate", moment(this.editData.start_date).valueOf());
      const valid =
        this?.editData?.start_date > this.dateOfNow() ? "valid" : "notValid";
      console.log(valid);
      return moment(this?.editData?.start_date).valueOf();
    },
    addPromoCodeClicked() {
      try {
        this.$refs["promocodeCreateDialogRef"].validate((isValid) => {
          if (isValid) {
            let finalFormData = JSON.parse(JSON.stringify(this.dialogFormData));
            let toBeSentForm = this.logicValidation(finalFormData);
            this.$emit("addPromoCodeClicked", { ...toBeSentForm });
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    editPromoCodeClicked() {
      this.$refs["promocodeCreateDialogRef"].validate((isValid) => {
        if (isValid) {
          let oldData = JSON.parse(JSON.stringify(this.editData));
          let newData = JSON.parse(JSON.stringify(this.dialogFormData));

          let editedNewData = { id: oldData.id };
          if (newData.code !== oldData.code) editedNewData.code = newData.code;
          if (
            newData.start_date !== oldData.start_date &&
            oldData?.is_target !== 1
          ) {
            editedNewData.start_date = moment(newData.start_date).valueOf();
          }
          if (
            newData.end_date !== oldData.end_date &&
            oldData?.is_target !== 1
          ) {
            editedNewData.end_date = moment(newData.end_date).valueOf();
          }
          // if (newData.isActive !== oldData.isActive)
          //   editedNewData.isActive = newData.isActive;
          if (newData.discount_amount !== oldData.discount_amount)
            editedNewData.discount_amount = newData.discount_amount;
          if (newData.min_amount !== oldData.min_amount)
            editedNewData.min_amount = newData.min_amount;
          if (newData.min_quantity !== oldData.min_quantity)
            editedNewData.min_quantity = newData.min_quantity;
          if (newData.max_uses !== oldData.max_uses)
            editedNewData.max_uses = newData.max_uses;
          if (newData.notes !== oldData.notes)
            editedNewData.notes = newData.notes;
          if (newData.T1_removable !== oldData.T1_removable)
            editedNewData.T1_removable = newData.T1_removable;

          if (newData.T1_regions_removable !== oldData.T1_regions_removable)
            editedNewData.T1_removable = newData.T1_removable;

          editedNewData.isActive = newData.isActive;
          editedNewData.wholesalerIds = newData.wholesalerIds;
          editedNewData.sectionKeys = newData.sectionKeys;
          editedNewData.categoryIds = newData.categoryIds;
          editedNewData.T1_regions_removable = newData.T1_regions_removable;
          editedNewData.payment_method = newData.payment_method;

          if (newData.selectAllFlag === true) {
            editedNewData.wholesalerIds = [];
            editedNewData.excluded_wholesaler_ids =
              newData.excluded_wholesaler_ids;
          } else {
            editedNewData.excluded_wholesaler_ids = [];
          }
          delete newData.selectAllFlag;

          if (newData.selectAllRegions === true) {
            editedNewData.T1_regions_removable = [];
          }
          delete newData.selectAllRegions;

          if (newData.start_date !== oldData.start_date)
            editedNewData.start_date = newData.start_date;
          if (newData.end_date !== oldData.end_date)
            editedNewData.end_date = newData.end_date;

          if (Object.keys(editedNewData).length > 1) {
            this.$emit("editPromoCodeClicked", editedNewData);
          } else {
            this.$message({
              message: this.$t("promocodesPageErrorUneditedMessage"),
              type: "error",
              duration: 4000,
            });
          }
        }
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.$refs["promocodeCreateDialogRef"].resetFields();
      this.dialogFormData = JSON.parse(JSON.stringify(this.defaultCreateData));
    },
    logicValidation(currentForm) {
      let selectAllFlagValue = currentForm.selectAllFlag;
      let wholesalersValue = currentForm.wholesalerIds;

      if (
        selectAllFlagValue === false &&
        wholesalersValue &&
        wholesalersValue.length == 0
      ) {
        this.$message({
          message: this.$t("promocodesPageErrorWholesalersSelectionMessage"),
          type: "error",
          duration: 5000,
        });
        // return;
        throw new Error("select all wholesalers error");
      }
      if (selectAllFlagValue === true) {
        currentForm.wholesalerIds = [];
      } else {
        currentForm.excluded_wholesaler_ids = [];
      }
      delete currentForm.selectAllFlag;

      let selectAllRegionsFlagValue = currentForm.selectAllRegions;
      let T1_removableValue = currentForm.T1_removable;
      let T1_regions_removableValue = currentForm.T1_regions_removable;
      if (T1_removableValue === 0) {
        if (currentForm.T1_regions_removable)
          delete currentForm.T1_regions_removable;
        delete currentForm.selectAllRegions;
      } else {
        if (
          selectAllRegionsFlagValue === false &&
          T1_regions_removableValue &&
          T1_regions_removableValue.length == 0
        ) {
          this.$message({
            message: this.$t("promocodesPageErrorRegionSelectionMessage"),
            type: "error",
            duration: 5000,
          });
          throw new Error("select all regions error");
        }
        if (selectAllRegionsFlagValue === true) {
          currentForm.T1_regions_removable = [];
        }
        delete currentForm.selectAllRegionsFlagValue;
      }

      if (this.promocodeType == "t0") {
        if (currentForm.hasOwnProperty("is_target"))
          delete currentForm.is_target;
        // if (currentForm.hasOwnProperty("start_date"))
        // delete currentForm.start_date;
        // if (currentForm.hasOwnProperty("end_date")) delete currentForm.end_date;
        if (currentForm.hasOwnProperty("start_date")) {
          currentForm.start_date = moment(currentForm.start_date).valueOf();
        }
        if (currentForm.hasOwnProperty("end_date")) {
          currentForm.end_date = moment(currentForm.end_date).valueOf();
        }
      }
      if (this.promocodeType == "target") {
        if (currentForm.hasOwnProperty("T1_removable"))
          delete currentForm.T1_removable;
      }
      if (!this.promocodeType || this.promocodeType == "") {
        if (currentForm.hasOwnProperty("is_target"))
          delete currentForm.is_target;
        if (currentForm.hasOwnProperty("start_date")) {
          currentForm.start_date = moment(currentForm.start_date).valueOf();
        }
        if (currentForm.hasOwnProperty("end_date")) {
          currentForm.end_date = moment(currentForm.end_date).valueOf();
        }
        //     newPromoData.start_date = moment(newPromoData.start_date).valueOf();
        //  newPromoData.end_date = moment(newPromoData.end_date).valueOf();
        // if (currentForm.hasOwnProperty("start_date"))
        //   delete currentForm.start_date;
        // if (currentForm.hasOwnProperty("end_date")) delete currentForm.end_date;
        if (currentForm.hasOwnProperty("T1_removable"))
          delete currentForm.T1_removable;
      }
      return currentForm;
    },
    autoAssignCheckboxValueChanged(checkboxValue) {
      if (checkboxValue === 0) {
        this.dialogFormData.max_uses = 1;
        this.dialogFormData.selectAllRegions = true;
        this.dialogFormData.T1_regions_removable = [];
      }
    },
    selectAllRegionsValueChanged(toggleValue) {
      if (toggleValue === true) {
        this.dialogFormData.T1_regions_removable = [];
      }
    },
    promocodeTypeChanged(chosenOption) {
      if (chosenOption == "t0") {
        this.dialogFormData.T1_removable = 1;
        this.autoAssignCheckboxValueChanged(0);
      } else {
        if (chosenOption == "target") {
          this.dialogFormData.is_target = true;
        } else {
          this.dialogFormData.T1_removable = 0;
          this.dialogFormData.is_target = false;
          this.dialogFormData.start_date = null;
          this.dialogFormData.end_date = null;
        }
      }
    },
  },
  watch: {
    showDialog(newVal) {
      this.internalFlag = newVal;
      if (newVal === false) {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        this.promocodeType = null;
      }
    },
    editData(newVal) {
      if (newVal) {
        let latestValue = JSON.parse(JSON.stringify(newVal));
        if (latestValue.promoter === "wholesaler") {
          latestValue.wholesaler_promocode = true;
        }
        if (latestValue.promoter === "static") {
          latestValue.wholesaler_promocode = false;
        }
        if (!latestValue.wholesalerIds) latestValue.wholesalerIds = [];
        if (!latestValue.sectionKeys) latestValue.sectionKeys = [];
        if (!latestValue.categoryIds) latestValue.categoryIds = [];

        if (
          latestValue.wholesalerIds &&
          latestValue.wholesalerIds.length == 0
        ) {
          latestValue.selectAllFlag = true;
        } else {
          latestValue.selectAllFlag = false;
        }
        if (
          latestValue.T1_regions_removable &&
          latestValue.T1_regions_removable.length == 0
        ) {
          latestValue.selectAllRegions = true;
        } else {
          latestValue.selectAllRegions = false;
        }
        if (latestValue.is_target) {
          this.promocodeType = "target";
        }
        if (latestValue.is_target && latestValue.is_target == 1) {
          latestValue.is_target = true;
          if (latestValue.start_date)
            latestValue.start_date = new Date(latestValue.start_date).getTime();
          if (latestValue.end_date)
            latestValue.end_date = new Date(latestValue.end_date).getTime();
        } else {
          if (latestValue.T1_removable) {
            this.promocodeType = "t0";
          }
        }
        this.dialogFormData = latestValue;
      } else {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
      }
    },
  },
  computed: {
    allWholesalers() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAvailableProviders =
        lookups && lookups.distributers ? lookups.distributers : [];
      currentAvailableProviders.forEach((singleProvider) => {
        singleProvider.key = singleProvider.id;
        singleProvider.value = singleProvider.name;
      });
      return currentAvailableProviders;
    },
    allSections() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAvailableSections =
        lookups && lookups.sections ? lookups.sections : [];
      currentAvailableSections.forEach((singleSection) => {
        singleSection.value = singleSection.title;
      });
      return currentAvailableSections;
    },
    allCategories() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAvailableCategories =
        lookups && lookups.category ? lookups.category : [];
      currentAvailableCategories.forEach((singleCategory) => {
        singleCategory.key = singleCategory.id;
        singleCategory.value = singleCategory.name;
      });
      return currentAvailableCategories;
    },
    allRegions() {
      let lookups = this.$store.getters.getAllLookups;
      let currentRegions = lookups && lookups.regions ? lookups.regions : [];
      return currentRegions;
    },
    discrepancyAmount() {
      let calculatedValue = null;
      try {
        calculatedValue =
          (this.dialogFormData.discount_amount /
            this.dialogFormData.min_amount) *
          100;
        if (!(calculatedValue > 10)) calculatedValue = null;
        else calculatedValue = Math.ceil(calculatedValue);
      } catch (error) {
        console.log("error :>> ", error);
        calculatedValue = null;
      }
      return calculatedValue;
    },
  },
  created() {
    this.dialogFormData = { ...this.defaultCreateData };
  },
};
</script>

<style lang="scss" scoped>
.promoCodeDate > div > div {
  width: 100%;
}
.promocodes-dialog__status-active-label {
  display: inline;
  margin-left: 10px;
}
[dir="rtl"] {
  .promocodes-dialog__status-active-label {
    display: inline;
    margin-right: 10px;
  }
}
.promocodes-dialog__discrepancy-message {
  text-align: center;
  color: red;
  margin-bottom: 10px;
}
</style>
