<template>
  <div>
    <div class="page-header">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Promo Codes</h3>
        </div>
        <div style="display: flex">
          <el-dropdown>
            <el-button type="primary" class="mr-1">
              Promo Codes Bulk Handling<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="customDropDown">
              <el-dropdown-item command="true">
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="mini"
                  @click="sheetActionClicked(null, 'create')"
                >
                  Create Promo Codes
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="true">
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="mini"
                  @click="sheetActionClicked(null, 'update')"
                >
                  Update Promo Codes
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="true">
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="mini"
                  @click="sheetActionClicked(null, 'userAssignment')"
                >
                  Bulk User Assignation
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <promocode-page-export-btns
            :filter="filters"
          ></promocode-page-export-btns>
        </div>
        <!-- <el-button
          type="primary"
          size="default"
          @click="dialogCreateBundleFormVisible = true"
          >{{ $t('CreateNewBundle') }}</el-button
        > -->
      </div>
    </div>
    <!-- ************************************************************************************ -->
    <!-- Filters and Add new promocode area -->
    <!-- ************************************************************************************ -->
    <div>
      <MetricFilterBox
        :defaultFilterData="metrics.states"
        v-model="metricsList"
        @clearFilters="clearFilters"
        @openMetric="saveMetircs"
        :clearBtnShowen="metricsList.length > 0"
      >
        <metricbox
          v-for="metric in metricsList"
          :key="metric.key"
          :label="$t(metric.label)"
          @close="closeMetric(metric, metric.filterKey)"
        >
          <template slot="filterBody">
            <component
              v-if="metric.key == 'is_active'"
              :is="metric.compName"
              v-model="filters[metric.key]"
              :state="
                metric.displayedData ? metric.displayedData : filters.state
              "
              @change="filtersChanged(metric.key, $event)"
              @enterClicked="
                filterEnterClicked(metric.key, filters[metric.key])
              "
              :meta="metric.meta ? metric.meta : null"
            ></component>
            <component
              :is="metric.compName"
              v-model="filters[metric.key]"
              :meta="metric.meta ? metric.meta : null"
              @change="filtersChanged(metric.key, $event)"
            ></component>
          </template>
        </metricbox>
      </MetricFilterBox>
      <el-row
        :gutter="20"
        type="flex"
        justify="end"
        class="u-bottom-margin--2x"
      >
        <el-col :span="2.5">
          <el-button
            class="u-bottom-margin--1x"
            type="primary"
            @click="showPromoCodeDialog = true"
          >
            {{ $t("promocodesPageAddPromocodeButtonLabel") }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- ************************************************************************************ -->
    <!-- Sheet hidden tags for uploading and downloading -->
    <!-- ************************************************************************************ -->
    <!-- <input
      ref="excel-input"
      style="display: none"
      type="file"
      accept=".xlsx, .csv"
      @change="handleClick"
    /> -->
    <a
      href="./file.txt"
      download="PromocodeAssignmentExport.csv"
      ref="sheet-download"
    ></a>
    <!-- ************************************************************************************ -->
    <!-- Table Area -->
    <!-- ************************************************************************************ -->
    <el-table :data="promocodesList" border stripe>
      <el-table-column
        prop="id"
        :label="$t('promocodesPageTableColumnId')"
        width="70"
      ></el-table-column>
      <el-table-column prop="code" :label="$t('promocodesPageTableColumnName')">
      </el-table-column>
      <el-table-column :label="$t('promocodesPageTableColumnUserAssign')">
        <template slot-scope="props">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <el-button
              type="info"
              class="u-right-margin--2x u-left-margin--2x"
              size="mini"
              @click="sheetActionClicked(props.row, 'assign')"
              :disabled="
                props.row.T1_removable === 1 ||
                props.row.is_active === 0 ||
                props.row.is_target === 1
              "
            >
              {{ $t("promocodesPageAssignButtonLabel") }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('promocodesPageTableColumnStatus')">
        <template slot-scope="props">
          <div>
            <el-switch
              v-model="props.row.isActive"
              @change="statusToggleChanged(props.row, $event)"
              :disabled="!props.row.isActive"
            >
            </el-switch>
            <span class="status-column__active-label">{{
              props.row.isActive
                ? $t("promocodesPageTableActiveLabel")
                : $t("promocodesPageTableInactiveLabel")
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="discount_amount"
        :label="$t('promocodesPageTableColumnDiscountAmount')"
      >
      </el-table-column>
      <el-table-column
        prop="max_uses"
        :label="$t('promocodesPageTableColumnMaxUsage')"
      >
      </el-table-column>
      <el-table-column
        prop="min_quantity"
        :label="$t('promocodesPageTableColumnMinItems')"
      >
      </el-table-column>
      <el-table-column
        prop="min_amount"
        :label="$t('promocodesPageTableColumnMinOrderValue')"
      >
      </el-table-column>
      <el-table-column prop="start_date" label="Start Date">
        <template slot-scope="prop">
          <div>{{ prop.row.start_date | moment("DD/MM/YYYY HH:mm") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="end_date" label="End Date">
        <template slot-scope="prop">
          <div>{{ prop.row.end_date | moment("DD/MM/YYYY HH:mm") }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="notes"
        :label="$t('promocodesPageTableColumnDescription')"
      >
        <template slot-scope="props">
          <div v-if="props.row.notes">
            {{
              props.row.notes.length > 100
                ? props.row.notes.substring(0, 100) + "..."
                : props.row.notes
            }}
          </div>
          <div v-else>null</div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('promocodesPageTableColumnOperations')">
        <template slot-scope="props">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <el-button
              type="info"
              class="u-top-margin--1x"
              size="mini"
              @click="editPromocodeClicked(props.row)"
            >
              {{ $t("promocodesPageEditButtonLabel") }}
            </el-button>
            <span></span>
            <el-button
              type="info"
              class="u-top-margin--1x"
              size="mini"
              @click="sheetActionClicked(props.row, 'append')"
              :disabled="
                props.row.T1_removable === 1 || props.row.is_active === 0
              "
            >
              {{ $t("promocodesPageAppendButtonLabel") }}
            </el-button>
            <span></span>

            <el-button
              type="info"
              class="u-top-margin--1x"
              size="mini"
              @click="exportSheetClicked(props.row)"
            >
              {{ $t("promocodesPageExportButtonLabel") }}
            </el-button>
            <span></span>
            <el-button
              type="danger"
              class="u-top-margin--1x"
              size="mini"
              @click="sheetActionClicked(props.row, 'delete')"
              :disabled="
                props.row.T1_removable === 1 || props.row.is_active === 0
              "
            >
              {{ $t("promocodesPageUnassignButtonLabel") }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="10"
        @current-change="pageChanged"
      ></el-pagination>
    </div>
    <!-- ************************************************************************************ -->
    <!-- Popup Area -->
    <!-- ************************************************************************************ -->
    <PromocodePageDialog
      :showDialog="showPromoCodeDialog"
      @closeDialog="closeDialogClicked"
      @addPromoCodeClicked="createNewPromocode"
      @editPromoCodeClicked="saveEditedPromocodeClicked"
      :editData="clickedSinglePromocode"
    />
    <!-- Uploader Dialog -->
    <el-dialog
      width="40%"
      :title="$t('uploadEntitesSheettitle')"
      :visible.sync="uploaderDialog"
      @close="uploaderDialogClosed"
    >
      <div class="uploaderDialog">
        <div class="uploaderDialogBtn__wrapper">
          <div class="uploadContainer">
            <div class="summary">
              <div class="summary__wrapper">
                <el-alert
                  :closable="false"
                  :title="summary"
                  type="warning"
                  show-icon
                >
                </el-alert>
              </div>
            </div>
            <el-upload
              ref="excel-input"
              :on-change="uploadExcelFileChange"
              :on-remove="removeExcelFile"
              :file-list="excelFileList"
              action="#"
              :multiple="false"
              :auto-upload="false"
              accept=".xlsx"
            >
              <el-button slot="trigger" size="mini" type="primary"
                >Select File</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>
      <div
        style="margin-top: 20px"
        v-if="sheetAction == 'userAssignment'"
        class="CustomRadioSelection"
      >
        <el-radio-group v-model="bulkUserAction" size="small">
          <el-radio label="assign" border>Assign Users</el-radio>
          <el-radio label="unassign" border>Unassign Users</el-radio>
          <el-radio label="append" border>Append Users</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="excelFileList.length == 0"
          icon="el-icon-top"
          type="primary"
          @click="uploadExcelSheet"
        >
          {{ $t("bundleImport") }}
        </el-button>
        <el-button @click="uploaderDialogClosed">{{
          $t("bundleDialogCloseLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- Uploader Dialog -->
  </div>
</template>

<script>
import { promocodesService } from "../../../services/promocodes.service";
import PromocodePageDialog from "./PromocodePageDialog.vue";
import { isEmpty, remove } from "lodash";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { promocodesMetrics } from "@/components/transaction/metricboxComponents";
import PromocodePageExportBtns from "./PromocodePageExportBtns.vue";
import { utils } from "../../../utils";
export default {
  name: "PromocodesPage",
  components: {
    PromocodePageDialog,
    MetricFilterBox,
    metricbox,
    ...promocodesMetrics,
    PromocodePageExportBtns,
  },
  data() {
    return {
      filters: {},
      promocodesList: [],
      pagination: {},
      showPromoCodeDialog: false,
      clickedSinglePromocode: null,
      sheetHolder: null,
      clickedPromocodeForSheets: null,
      sheetAction: null,
      metricsList: [],
      uploaderDialog: false,
      excelFileList: [],
      summary: "",
      bulkUserAction: "assign",
      metrics: {
        states: [
          {
            key: "promocode",
            label: "Promocode Name",
            compName: "stringMetric",
            meta: {
              placeholder: "Input name",
            },
          },
          {
            key: "promocodeId",
            label: "Promocode ID",
            compName: "stringMetric",
            meta: {
              placeholder: "Input number",
            },
          },
          {
            key: "creationDate",
            label: "Creation Date",
            compName: "dateRangeMetric",
          },
          {
            key: "actualDate",
            label: "Date",
            compName: "dateRangeMetric",
          },
          {
            key: "is_active",
            label: "Promo Code Status",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "Active",
              },
              {
                value: "Inactive",
              },
            ],
          },
          // {
          //   key: "isActive",
          //   label: "Is Active ? ",
          //   compName: "dynamicTypeMetric",
          //   displayedData: [
          //     {
          //       value: "Yes",
          //     },
          //     {
          //       value: "No",
          //     },
          //   ],
          // },
          // {
          //   key: "payment_method",
          //   label: "Payment Method",
          //   compName: "dynamicDropdownMetric",
          //   meta: {
          //     data: [
          //       {
          //         key: "cash",
          //         label: "Cash",
          //       },
          //       {
          //         key: "credit",
          //         label: "Credit",
          //       },
          //       {
          //         key: "both",
          //         label: "Both",
          //       },
          //     ],
          //     placeholder: "Select Type",
          //     multiple: false,
          //   },
          // },
        ],
      },
    };
  },
  computed: {
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    currentPage() {
      return this.$route.query.page
        ? Number.parseInt(this.$route.query.page)
        : 1;
    },
  },
  methods: {
    async getPromocodes(page = 1) {
      const filters = this.filters;
      try {
        const response = await promocodesService.fetchPromocodes(page, filters);
        this.promocodesList = response.promocodesList;
        this.pagination = response.pagination;
        return response;
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
    async statusToggleChanged(rowObject, value) {
      try {
        let updateObj = { id: rowObject.id };
        updateObj.isActive = value;
        const response = await promocodesService.updatePromocode(updateObj);
        if (response && response.status === 200) {
          let responseSuccessMessage =
            rowObject.code +
            " " +
            this.$t("promocodesPageSuccessReponse1") +
            " " +
            `${
              value
                ? this.$t("promocodesPageTableActiveLabel")
                : this.$t("promocodesPageTableInactiveLabel")
            }`;
          this.$globalFunctions.popupMessageWrapper(
            responseSuccessMessage,
            "success",
            4000
          );
        }
      } catch (error) {
        rowObject.is_active = !value;
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
        setTimeout(() => {
          this.getPromocodes(this.currentPage);
        }, 2000);
      }
    },
    async createNewPromocode(newPromoData) {
      // if(newPromoData?.is_target === false){
      //   newPromoData.start_date = moment(newPromoData.start_date).startOf('day').valueOf();
      //  newPromoData.end_date = moment(newPromoData.end_date).endOf('day').valueOf();
      // }
      try {
        const response = await promocodesService.createPromocode(newPromoData);
        if (response && response.status === 200) {
          let responseSuccessMessage =
            this.$t("promocodesPageSuccessResponse2") +
            " " +
            response.data.promocode.code +
            " " +
            this.$t("promocodesPageSuccessResponse3");
          this.$globalFunctions.popupMessageWrapper(
            responseSuccessMessage,
            "success",
            4000
          );
          this.showPromoCodeDialog = false;
          this.getPromocodes();
        }
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 5000);
      }
    },
    editPromocodeClicked(clickedPromocode) {
      this.clickedSinglePromocode = clickedPromocode;
      this.showPromoCodeDialog = true;
    },
    closeDialogClicked() {
      this.showPromoCodeDialog = false;
      this.clickedSinglePromocode = null;
    },
    async saveEditedPromocodeClicked(editedPromoData) {
      try {
        const response = await promocodesService.updatePromocode(
          editedPromoData
        );
        if (response && response.status === 200) {
          let responseSuccessMessage =
            this.$t("promocodesPageSuccessResponse2") +
            " '" +
            this.clickedSinglePromocode.code +
            "' " +
            this.$t("promocodesPageSuccessResponse4");
          this.$globalFunctions.popupMessageWrapper(
            responseSuccessMessage,
            "success",
            4000
          );
          this.showPromoCodeDialog = false;
          this.clickedSinglePromocode = null;
          this.getPromocodes();
        }
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 5000);
      }
    },
    filtersChanged(key, value) {
      if (!(typeof value === "number") && isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.promocodes = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.reloadPage();
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    sheetActionClicked(singlePromo, chosenAction) {
      this.uploaderDialog = true;
      this.clickedPromocodeForSheets = singlePromo;
      this.sheetAction = chosenAction;
      if (this.sheetAction === "assign")
        this.summary = `You will Assign Users to promo code ${singlePromo.code} with id ${singlePromo.id}`;
      if (this.sheetAction === "append")
        this.summary = `You will Append Users to promo code ${singlePromo.code} with id ${singlePromo.id}`;
      if (this.sheetAction === "delete")
        this.summary = `You will Delete Users to promo code ${singlePromo.code} with id ${singlePromo.id}`;
      if (this.sheetAction === "create")
        this.summary = `You will Bulk upload an excel sheet to create promo codes.`;
      if (this.sheetAction === "update")
        this.summary = `You will Bulk upload an excel sheet to update promo codes.`;
      if (this.sheetAction === "userAssignment") {
        this.summary = `You will bulk upload an excel sheet to handle users to promo codes.`;
      }

      // this.$refs["excel-input"].click();
    },
    bulkAction(chosenAction) {
      this.sheetAction = chosenAction;
      this.$refs["excel-input"].click();
    },
    uploadExcelSheet() {
      this.sheetActionHandler();
    },
    sheetActionHandler() {
      let form = new FormData();
      // form.append("file", this.sheetHolder);
      form.append("file", this.excelFileList[0].raw);
      // let promocodeId = this.clickedPromocodeForSheets?.id ?? null;
      let promocodeId = this.clickedPromocodeForSheets?.id ?? null;
      this.sheetAction == "userAssignment"
        ? (this.sheetAction = this.bulkUserAction)
        : null;
      promocodesService
        .uploadPromocodeSheetAction(promocodeId, this.sheetAction, form)
        .then((res) => {
          const errorFileHeadersName =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          if (
            res.status == 200 &&
            res.headers["content-type"] != errorFileHeadersName
          ) {
            this.$message({
              // message: `Sheet '${this.sheetHolder.name}' uploaded successfully`,
              message: `${res.data.message ?? "file uploaded successfully"}`,
              type: "success",
              duration: 6000,
            });
          }
          if (
            res.status == 200 &&
            (this.sheetAction == "create" || this.sheetAction == "update") &&
            res.headers["content-type"] == errorFileHeadersName
          ) {
            const responseData = res.data;
            let blob = new Blob([responseData], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "BulkPromoCodesCreationErrors", "xlsx");
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
          if (
            res.status == 200 &&
            (this.sheetAction == "append" ||
              this.sheetAction == "assign" ||
              this.sheetAction == "unassign") &&
            res.headers["content-type"] == errorFileHeadersName
          ) {
            const responseData = res.data;
            let blob = new Blob([responseData], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "BulkPromoCodesUsersErrors", "xlsx");
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
          if (res?.response?.status === 400) {
            this.$globalFunctions.popupMessageWrapper(
              `There is an error occured`,
              "error",
              2000
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            message: this.$t("promocodesPageCatchErrorMessage"),
            type: "error",
            duration: 6000,
          });
        })
        .finally(() => {
          this.clickedPromocodeForSheets = null;
          this.sheetAction = null;
          this.sheetHolder = null;
          this.summary = "";
          this.excelFileList = [];
          this.uploaderDialog = false;
          this.bulkUserAction = "assign";
        });
    },
    uploadExcelFileChange(file) {
      // Allow only one file in the file list not multiple
      this.excelFileList = [file];
      const rawFile = this.excelFileList[0];
      if (!rawFile) return;
      if (/\.(csv|xlsx)$/.test(rawFile.name)) {
        this.sheetHolder = rawFile;
      } else {
        this.$message({
          message: this.$t("promocodesPageFileValidationErrorMessage"),
          type: "error",
          duration: 4000,
        });
        this.excelFileList = [];
      }
    },
    removeExcelFile() {
      this.excelFileList = [];
    },
    uploaderDialogClosed() {
      this.uploaderDialog = false;
      this.excelFileList = [];
      this.summary = "";
      this.bulkUserAction = "assign";
    },
    exportSheetClicked(clickedRow) {
      let promocodeId = clickedRow.id;
      promocodesService
        .exportPromocodeSheet(promocodeId)
        .then((res) => {
          if (res.status == 200) {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `PromocodeAssignmentExport-${clickedRow.code}.csv`
            );
            this.$refs["sheet-download"].click();
            // let resMessage =`$t('promocodesPageSuccessResponse5') PromocodeAssignmentExport-${clickedRow.code}.csv `
            let resMessage =
              this.$t("promocodesPageSuccessResponse5") +
              ` PromocodeAssignmentExport-${clickedRow.code}.csv`;
            this.$message({
              message: resMessage,
              type: "success",
              duration: 6000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            message: this.$t("promocodesPageCatchErrorMessage"),
            type: "error",
            duration: 6000,
          });
        });
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.promocodes = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsPromocodes", []);
      this.reloadPage();
    },
    saveMetircs() {
      localStorage.setItem(
        "metricsPromocodes",
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        "metricsPromocodes",
        JSON.stringify(this.metricsList)
      );
      this.metricsList = [...this.metricsList];
    },
    filterEnterClicked(key, value) {
      this.filtersChanged(key, value);
    },
    reloadPage() {
      if (this.$route.fullPath != "/marketing/promocodes?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getPromocodes(this.currentPage);
    },
    initialFiltersHandler() {
      let filtersSession = sessionStorage.getItem("filter");
      if (filtersSession) {
        filtersSession = JSON.parse(filtersSession);
        if (filtersSession.promocodes) {
          const filtersObject = filtersSession.promocodes;
          if (filtersObject && filtersObject.filters)
            this.filters = { ...filtersObject.filters };
        }
      }
      let metricsSession = localStorage.getItem("metricsPromocodes");
      if (metricsSession) {
        this.metricsList = JSON.parse(metricsSession);
      }
    },
  },
  created() {
    this.initialFiltersHandler();
    this.getPromocodes(this.currentPage);
  },
  watch: {
    $route(to) {
      this.getPromocodes(to.query.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.CustomRadioSelection > div {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.status-column__active-label {
  padding-left: 10px;
}
[dir="rtl"] {
  .status-column__active-label {
    padding-right: 10px;
  }
}
.customDropDown {
  li:nth-child(3) button {
    width: 100%;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
  }
}
/* .uploaderDialog {
    .el-upload {
      button {
        span {
          font-size: 14px;
        }
      }
    } */
.summary {
  &__wrapper {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}

/* } */
</style>
