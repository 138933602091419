<template>
  <div>
    <el-tooltip
      content="Must Filter by Creation Date (maximum of 1 year period)"
      :disabled="isDateSelected"
      placement="top"
      effect="dark"
    >
      <el-dropdown
        @command="handleSheetExport"
        :disabled="!isDateSelected"
      >
        <el-button
          :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
         type="primary"
                  icon="el-icon-bottom"
                  size="large"
          :disabled="!isDateSelected"
        >
          {{ $t("GlobalExportFileButtonLabel") }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="{
              fileName: 'promocodes',
              action: 'update',
              exportType: 'exportPromocodesSheet',
            }"
            >Promocodes</el-dropdown-item
          >
          <el-dropdown-item
            :command="{
              fileName: 'PromocodesUsers',
              action: 'update',
              exportType: 'exportPromocodesUsersAssignmentSheet',
            }"
            >Users</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>
    <el-dropdown @command="handleSheetExport">
      <el-button
        size="large"
        icon="el-icon-download"
        type="info"
        :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
        plain
      >
        {{ $t("GlobalTemplateDownloadButtonLabel") }}
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :command="{
            fileName: 'PromocodeTemplate',
            action: 'create',
            exportType: 'exportPromocodesSheet',
          }"
          >Promocode Creation</el-dropdown-item
        >
        <el-dropdown-item
          :command="{
            fileName: 'PromocodesUsersTemplate',
            action: 'create',
            exportType: 'exportPromocodesUsersAssignmentSheet',
          }"
          >Users Assignation</el-dropdown-item
        >
        <el-dropdown-item
          :command="{
            fileName: 'PromocodesUsersTemplate',
            action: 'singleAssignment',
            exportType: 'exportPromocodeSingleAssignation',
          }"
          >Single Promocode Assignation</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { promocodesService } from "../../../services/promocodes.service";
import { utils } from "../../../utils";
export default {
  name: "PromocodesExportBtns",
  props: { filter: { type: Object, required: false } },
  computed: {
    isDateSelected() {
      const { creationDate, actualDate } = this.filter;
      if (creationDate && !actualDate) {
        const startDate = new Date(creationDate[0]);
        const endDate = new Date(creationDate[1]);
        const diffInMs = endDate.getTime() - startDate.getTime();
        const diffInDays = diffInMs / (1000 * 3600 * 24);
        if (diffInDays > 366) return false;
        return true;
      }
      else if (!creationDate && actualDate) {
        const startDate = new Date(actualDate[0]);
        const endDate = new Date(actualDate[1]);
        const diffInMs = endDate.getTime() - startDate.getTime();
        const diffInDays = diffInMs / (1000 * 3600 * 24);
        if (diffInDays > 366) return false;
        return true;
      }else if(creationDate && actualDate){
         const creationStartDate = new Date(creationDate[0]);
        const creationEndDate = new Date(creationDate[1]);
        const creationDiffInMs = creationEndDate.getTime() - creationStartDate.getTime();
        const creationDiffInDays = creationDiffInMs / (1000 * 3600 * 24);
        const startDate = new Date(actualDate[0]);
        const endDate = new Date(actualDate[1]);
        const diffInMs = endDate.getTime() - startDate.getTime();
        const diffInDays = diffInMs / (1000 * 3600 * 24);
        if (creationDiffInDays > 366 && diffInDays > 366 ) return false;
        return true;
      }
      return false;
    },
  },
  methods: {
    async handleSheetExport({ action, fileName, exportType }) {
      try {
        const response = await promocodesService[exportType](
          action,
          this.filter
        );

        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, fileName, "xlsx");

          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        this.$message({
          message:
            utils.decodeArrayBuffer(error.response.data)?.message ??
            utils.errorMessageExtractor(error),
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style></style>
